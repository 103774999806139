import React from 'react'
import { useTranslation } from 'react-i18next'
// Styles
import * as S from './StatsBox.styles'

export default function StatsBox({ totalIssuers, totalBadges, totalAwards }) {
  const { t } = useTranslation()

  return (
    <S.StatsContainer>
      <S.StatsLabel>{t('general.status')}</S.StatsLabel>
      <S.Card>
        <S.Item>
          <span>{totalIssuers}</span>
          <label>{totalIssuers === 1 ? t('general.issuers') : t('general.issuers')}</label>
        </S.Item>
        <S.Item>
          <span>{totalBadges}</span>
          <label>
            {totalBadges === 1
              ? t('badge-card.cards.template-text')
              : t('badge-card.cards.templates-text')}
          </label>
        </S.Item>
        <S.Item>
          <span>{totalAwards}</span>
          <label>
            {totalAwards === 1
              ? t('badge-card.cards.award-text')
              : t('badge-card.cards.awards-text')}
          </label>
        </S.Item>
      </S.Card>
    </S.StatsContainer>
  )
}
