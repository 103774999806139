// media.js
const sizes = {
  small: '576px',
  medium: '768px',
  large: '992px',
  extraLarge: '1200px'
}

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => `
    @media (max-width: ${sizes[label]}) {
      ${args}
    }
  `
  return acc
}, {})

export const combineMedia = (breakpoints, ...args) => {
  return breakpoints.map((breakpoint) => media[breakpoint](...args)).join('')
}
