// Modules
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Card, CardBody, TabPane, NavLink, Nav, NavItem, TabContent } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
// Actions
import { createIssuer, updateIssuer } from '../../../ducks/Issuers.ducks'
// Componets
import Breadcrumb from './components/Breadcrumb'
import IssuerForm from './components/IssuerForm'
// Utils
import { urlRegex, routerPath } from '../../../constants/constants'
// Assets
import IconToastError from '../../../assets/image/icon-toast-error.png'
import IconToastSuccess from '../../../assets/image/icon-toast-success.svg'

const UpsertIssuer = ({ t, auth, chambers, issuer, editMode }) => {
  const history = useHistory()

  const { authToken } = auth || {}

  const chambersList = chambers.map((chamber) => ({
    id: chamber._id,
    value: chamber.name,
    label: chamber.name
  }))

  const validationSchema = Yup.object({
    chamber: Yup.object().required(t('error-msg.required')),
    url: Yup.string()
      .required(t('error-msg.required'))
      .matches(urlRegex, t('error-msg.url-not-valid')),
    linkedinOrganizationId: Yup.string()
      .matches(/^\d+$/, t('error-msg.number-error'))
      .min(6, t('error-msg.min6')),
    email: Yup.string().email(t('error-msg.invalid-email')).required(t('error-msg.required')),
    description: Yup.string()
      .trim()
      .required(t('error-msg.required'))
      .min(15, t('error-msg.min15'))
      .max(5000, t('error-msg.max5000'))
  })

  const initialValues = {
    chamber: issuer
      ? {
          id: issuer.chamberId ?? '',
          value: issuer.name ?? '',
          label: issuer.name ?? ''
        }
      : '',
    description: issuer?.description ? issuer.description.replaceAll('<br/>', '\n') : '',
    url: issuer?.website ?? '',
    linkedinOrganizationId: issuer?.linkedinOrganizationId ?? '',
    email: issuer?.email ?? ''
  }

  const handleBackToAction = (issuer, values = null) => {
    const targetPath = editMode
      ? {
          pathname: routerPath.ISSUER_DETAILS,
          state: { id: issuer?.id, name: values?.chamber?.value || issuer?.value }
        }
      : `/?token=${authToken || authToken?.token}`

    history[editMode ? 'replace' : 'push'](targetPath)
  }

  const handleSubmit = async (values) => {
    const data = {
      id: values.chamber.id,
      url: values.url,
      linkedinOrganizationId:
        values.linkedinOrganizationId && values.linkedinOrganizationId !== ''
          ? values.linkedinOrganizationId
          : '',
      email: values.email,
      description: values.description.trim().replaceAll('\n', '<br/>')
    }

    const action = editMode ? updateIssuer : createIssuer
    const toastMessage = editMode
      ? t('issuers.form.messages.success-edit')
      : t('issuers.form.messages.success-create')

    const toastIcon = <img src={IconToastSuccess} className="img-fluid" alt="images" />

    action({
      id: issuer?.id,
      data,
      successCb: () => {
        handleBackToAction(issuer, values)
        toast.success(toastMessage, { icon: toastIcon })
      },
      errorCb: () => {
        toast.error(t('issuers.form.messages.error'), {
          icon: <img src={IconToastError} className="img-fluid" alt="images" />
        })
      }
    })
  }

  return (
    <>
      <Breadcrumb
        t={t}
        authToken={authToken}
        issuer={issuer}
        editMode={editMode}
        routerPath={routerPath}
      />
      <div className="create-tab-section">
        <Card className="create-card">
          <CardBody className="create-body">
            <Nav pills className="mb-0">
              <NavItem>
                <NavLink active={true}>
                  <span>{t('issuers.form.tab.title')}</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab="1">
              <TabPane tabId="1">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnBlur={true}
                  validateOnChange={true}
                  onSubmit={handleSubmit}>
                  {({ errors, touched, values, setFieldValue }) => (
                    <IssuerForm
                      t={t}
                      issuer={issuer}
                      editMode={editMode}
                      errors={errors}
                      touched={touched}
                      values={values}
                      chambersList={chambersList}
                      setFieldValue={setFieldValue}
                      handleBackToAction={handleBackToAction}
                    />
                  )}
                </Formik>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

UpsertIssuer.propTypes = {
  t: PropTypes.func,
  auth: PropTypes.object,
  chambers: PropTypes.array,
  issuer: PropTypes.object,
  editMode: PropTypes.bool
}

export default UpsertIssuer
