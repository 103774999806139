// Modules
import styled from 'styled-components'
import { combineMedia } from '../../helpers/media'

// Container for the whole section
export const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  padding: 4px 10px;
  min-width: 350px;

  /* Combine Medium and Small */
  ${combineMedia(
    ['large', 'medium', 'small'],
    `
    margin: 20px 0;
  `
  )}
`

// Label for status
export const StatsLabel = styled.label`
  font-size: 10px;
  color: #888;
  text-transform: uppercase;
  margin-bottom: 8px;
  text-align: left;
`

// Card container to hold items
export const Card = styled.div`
  display: flex;
  justify-content: space-between;
`

// Individual item inside the card
export const Item = styled.div`
  display: flex;
  align-items: center;
  & span {
    font-size: 17px;
    font-weight: bold;
    color: #003366;
    margin-right: 4px;
  }

  & label {
    font-size: 11px;
    margin-top: 4px;
    color: #003366;
  }
`
