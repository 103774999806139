// Modules
import { useState } from 'react'
import { Card, CardBody, NavLink, Nav, NavItem } from 'reactstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import differenceWith from 'lodash.differencewith'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
// Actions
import { createBadge, updateBadge, uploadBadgeImage } from '../../../ducks/Badges.ducks'
// Components
import Breadcrumb from './components/Breadcrumb/Breadcrumb'
import BadgeForm from './components/BadgeForm/BadgeForm'
// Constants
import { routerPath, urlRegex } from '../../../constants/constants'
// Assets
import IconToastError from '../../../assets/image/icon-toast-error.png'
import IconToastSuccess from '../../../assets/image/icon-toast-success.svg'

const UpsertBadge = ({ badge, editMode }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const { issuerId, issuerName } = location?.state || {}

  const [activeTab, setActiveTab] = useState('1')
  const [imageDelete, setImageDelete] = useState(false)
  const [issuerData] = useState([{ id: issuerId, value: issuerName, label: issuerName }])

  const initialValues = {
    issuer: {
      id: issuerId,
      value: issuerName,
      label: issuerName
    },
    name: editMode ? badge?.name : '',
    description: editMode ? badge?.description?.replaceAll('<br/>', '\n') : '',
    image: editMode ? badge?.image : [],
    criteriaURL: editMode ? badge?.criteriaURL : '',
    criteriaDescription: editMode ? badge?.criteriaDescription?.replaceAll('<br/>', '\n') : '',
    status: badge?.status ?? 'active'
  }

  const validationSchema = Yup.object({
    issuer: Yup.object().required(t('error-msg.required')),
    name: Yup.string().trim().required(t('error-msg.required')),
    description: Yup.string()
      .trim()
      .min(15, t('error-msg.min15'))
      .max(5000, t('error-msg.max5000'))
      .required(t('error-msg.required')),
    image: Yup.mixed().required(t('error-msg.required')),
    criteriaURL: Yup.string()
      .trim()
      .required(t('error-msg.url-not-valid'))
      .matches(urlRegex, t('error-msg.url-not-valid')),
    criteriaDescription: Yup.string()
      .trim()
      .min(15, t('error-msg.min15'))
      .max(5000, t('error-msg.max5000'))
      .required(t('error-msg.required'))
  })

  const handleBackToAction = () => {
    const targetPath = editMode
      ? {
          pathname: routerPath.BADGE_DETAILS,
          state: { id: badge?.id, name: badge?.name }
        }
      : {
          pathname: routerPath.ISSUER_DETAILS,
          state: { id: issuerId, name: issuerName }
        }

    history['replace'](targetPath)
  }

  const handleTabToggle = (tab, values) => {
    const isDataEmpty = (data) =>
      !data ||
      !data.name ||
      !data.description ||
      (data.image && data.image.length < 1) ||
      data.name.trim() === '' ||
      data.description.trim() === ''

    if (!values || !isDataEmpty(values)) {
      setActiveTab(tab)
    } else {
      toast.error(t('badges.upset.messages.empty-form'), {
        icon: <img src={IconToastError} className="img-fluid" />
      })
    }
  }

  const handleBadgeImageDelete = () => {
    setImageDelete(true)
  }

  const handleSubmit = async (values) => {
    // eslint-disable-next-line prefer-const
    const data = {
      name: values.name.trim(),
      issuerId: values.issuer.id,
      description: values.description.trim().replaceAll('\n', '<br/>'),
      criteriaURL: values.criteriaURL,
      criteriaDescription: values.criteriaDescription.trim().replaceAll('\n', '<br/>'),
      status: values.status
    }

    const badgeData = cloneDeep(badge || {})

    if (!values.image instanceof File) {
      const diffs = differenceWith(Object.entries(data), Object.entries(badgeData), isEqual)
      if (!diffs || diffs.length === 0) {
        handleBackToAction()

        return
      }
    }

    const action = editMode ? updateBadge : createBadge

    const toastMessage = editMode
      ? t('badges.actions.messages.success-edit')
      : t('badges.actions.messages.success-create')

    const toastIcon = <img src={IconToastSuccess} className="img-fluid" alt="images" />

    uploadBadgeImage({
      data: { image: values.image, ...(imageDelete ? { oldImage: imageDelete } : {}) },
      successCb: (image) => {
        data.imageURL = image ?? badge?.image
        action({
          id: badge?.id,
          data,
          successCb: () => {
            handleBackToAction()
            toast.success(toastMessage, { icon: toastIcon })
          },
          errorCb: () => {
            toast.error(t('badges.actions.messages.error'), {
              icon: <img src={IconToastError} className="img-fluid" alt="images" />
            })
          }
        })
      },
      errorCb: () => {
        toast.error(t('badges.actions.messages.error'), {
          icon: <img src={IconToastError} className="img-fluid" alt="images" />
        })
      }
    })
  }

  return (
    <>
      <Breadcrumb translate={t} routerPath={routerPath} location={location} />
      <div className="edit-tab-section">
        <Card className="edit-card">
          <CardBody className="edit-body">
            <Nav pills className="mb-0">
              <NavItem>
                <NavLink className={activeTab === '2' ? 'success' : ''} active={activeTab === '1'}>
                  <span>{t('create-badge-page.tab1.title')}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={activeTab === '2'}>
                  <span>{t('create-badge-page.tab2.title')}</span>
                </NavLink>
              </NavItem>
              <div className="count-div">
                {activeTab === '1' ? (
                  <div className="one">1/2</div>
                ) : (
                  <div className="two">2/2</div>
                )}
              </div>
            </Nav>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnBlur={true}
              validateOnChange={true}
              onSubmit={handleSubmit}>
              {({ errors, touched, values, setFieldValue }) => (
                <BadgeForm
                  translate={t}
                  editMode={editMode}
                  badge={badge}
                  errors={errors}
                  touched={touched}
                  values={values}
                  imageDelete={imageDelete}
                  activeTab={activeTab}
                  issuerData={issuerData}
                  handleTabToggle={handleTabToggle}
                  handleBackToAction={handleBackToAction}
                  setFieldValue={setFieldValue}
                  handleBadgeImageDelete={handleBadgeImageDelete}
                />
              )}
            </Formik>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

UpsertBadge.propTypes = {
  badge: PropTypes.object,
  editMode: PropTypes.bool
}

export default UpsertBadge
