// Modules
import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Table } from 'reactstrap'
// Components
import { convertDateFormat } from '../../../../../helpers/dates'
import Checkbox from '../../../../../components/Checkbox'
// Helpers
import { routerPath } from '../../../../../constants/constants'
// Style
import styles from './ListListing.css'

const ListListing = ({
  t,
  history,
  badges,
  bulkSelection,
  handleGroupSelection,
  toggleBulkSelection
}) => {
  const sortedBadges = useMemo(() => {
    return badges.slice().sort((a, b) => a.name.localeCompare(b.name))
  }, [badges])

  const isFullySelected = bulkSelection && bulkSelection.length === badges.length
  const isPartiallySelected =
    bulkSelection && bulkSelection.length > 0 && bulkSelection.length < badges.length

  const handleGroupSelectionClick = useCallback(() => {
    handleGroupSelection(isFullySelected, isPartiallySelected)
  }, [handleGroupSelection, isFullySelected, isPartiallySelected])

  const handleCheckboxClick = useCallback(
    (badgeId) => {
      toggleBulkSelection({
        operation: bulkSelection && bulkSelection.includes(badgeId) ? 'unselect' : 'select',
        badgeId
      })
    },
    [bulkSelection, toggleBulkSelection]
  )

  const handleBadgeDetailsButtonClick = useCallback(
    (badge) => {
      history.push({
        pathname: routerPath.BADGE_DETAILS,
        state: {
          id: badge.id,
          issuerId: badge.issuer.id,
          issuerName: badge.issuer.name
        }
      })
    },
    [history]
  )

  return (
    <Table responsive hover borderless className="custom-table">
      <thead>
        <tr>
          <th>
            <Checkbox
              checked={isFullySelected}
              indeterminate={isPartiallySelected}
              onClick={handleGroupSelectionClick}
            />
          </th>
          <th>{t('badges.listing.table.badge')}</th>
          <th>{t('badges.listing.table.issuer')}</th>
          <th>{t('badges.listing.table.created_at')}</th>
          <th>{t('badges.listing.table.awards')}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {sortedBadges.length !== 0 &&
          sortedBadges.map((badge, i) => (
            <tr key={i}>
              <td>
                <Checkbox
                  checked={bulkSelection && bulkSelection.includes(badge.id)}
                  onClick={() => handleCheckboxClick(badge.id)}
                />
              </td>
              <td>{badge.name}</td>
              <td>{badge.issuer.name}</td>
              <td>{convertDateFormat(badge.createdAt, false, false)}</td>
              <td>
                {badge.awardsCount}
                &nbsp;
                {badge.awardsCount > 1
                  ? t('badge-card.cards.awards-text')
                  : t('badge-card.cards.award-text')}
              </td>
              <td>
                <Button
                  color="primary"
                  className="btn-primary btn-20 float-right"
                  style={styles.detailsButton}
                  onClick={() => handleBadgeDetailsButtonClick(badge)}>
                  {t('badges.listing.buttons.details')}
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  )
}

ListListing.propTypes = {
  t: PropTypes.func,
  history: PropTypes.object,
  badges: PropTypes.array,
  bulkSelection: PropTypes.array,
  handleGroupSelection: PropTypes.func,
  toggleBulkSelection: PropTypes.func
}

export default ListListing
