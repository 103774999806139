import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

// Styled component for the badge container
const StatusDisplay = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'active'
})`
  display: inline-flex;
  padding: 6px 12px;
  font-size: 12px;
  border-radius: 8px;
  color: ${({ active }) => (active ? '#4CAF50' : '#FFC107')};
  background-color: ${({ active }) => (active ? '#D1E8B8' : '#FFF4D1')};
  border: 2px solid ${({ active }) => (active ? '#4CAF50' : '#FFC107')};
  text-align: center;
  transition: all 0.3s ease;
`
const ACTIVE = 'active'
const INACTIVE = 'inactive'

const StatusBadge = ({ status }) => {
  const { t } = useTranslation()

  const isActive = status === ACTIVE || !status

  return (
    <StatusDisplay active={isActive}>
      {isActive ? t('general.active') : t('general.inactive')}
    </StatusDisplay>
  )
}

export default StatusBadge
