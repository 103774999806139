import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

// Styled components for the toggle switch
const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

const FormLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #23366c;
`

const ToggleContainer = styled.div`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`

const StyledToggle = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'active'
})`
  width: 40px;
  height: 22px;
  background-color: ${(props) => (props.active ? '#4caf50' : '#ccc')};
  border-radius: 15px;
  position: relative;
  transition: background-color 0.3s ease;
`

const ToggleThumb = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'active'
})`
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: ${(props) => (props.active ? '22px' : '2px')};
  transition: left 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`

const Status = styled.span.withConfig({
  shouldForwardProp: (prop) => prop !== 'active'
})`
  margin-left: 12px;
  font-size: 14px;
  color: ${(props) => (props.active ? '#23366c' : '#666')};
  transition: color 0.3s ease;
`
export const ACTIVE = 'active'
export const INACTIVE = 'inactive'

const ToggleSwitch = ({ label, field, form }) => {
  const { t } = useTranslation()

  // Extract the field and form props
  const { value, name } = field
  const { setFieldValue } = form

  // Toggle function
  const handleToggle = () => {
    const newValue = value === ACTIVE ? INACTIVE : ACTIVE
    setFieldValue(name, newValue)
  }

  return (
    <FormGroup>
      <FormLabel>{label}</FormLabel>
      <ToggleContainer onClick={handleToggle}>
        {/* Hidden checkbox for accessibility */}
        <HiddenCheckbox checked={value === ACTIVE} onChange={handleToggle} />
        <StyledToggle active={value === ACTIVE}>
          <ToggleThumb active={value === ACTIVE} />
        </StyledToggle>
        <Status active={value === ACTIVE}>
          {value === ACTIVE ? t('general.active') : t('general.inactive')}
        </Status>
      </ToggleContainer>
    </FormGroup>
  )
}

export default ToggleSwitch
